import { preparePhoto } from "../api/util";
import { IItemPage } from "../types/item";
import { IAlbum, IModifyAlbumResponse, IPhoto, IProcessedModifyAlbumResponse } from "../types/photo";

export function filterAlbumsAuto(albumsPage: IItemPage<IAlbum>): IItemPage<IAlbum> {
  return {
    ...albumsPage,
    value: albumsPage.value.filter((album) => !album.tags || !album.tags.find((tag) => tag.name === "__Auto"))
  };
}

export function processModifyAlbumItemsResponse(
  results: PromiseSettledResult<IModifyAlbumResponse>[],
  photoIds: string[],
  batchSize: number
): IProcessedModifyAlbumResponse {
  // NOTE: COB add to album API functions as copyTo, and returns a list of results coresponding to each photo to add.
  // 1. If a photo is added successfully, result (defined as photoResult below) will include
  // the original photo's id (photoResult.id) and the copied item of that photo (photoResult.copiedItem).
  // 2. If a photo is added successfully but the API fails to retrieve the newly copied item, the result will
  // include the orginal photo's id and an error object with a specific error code "copiedAlbumItemNotFound".
  // 3. If a photo is not added, result will just include an error object.

  const flattenedResult: PromiseSettledResult<void>[] = [];
  const modifiedPhotoIds: string[] = [];
  const addedPhotos: IPhoto[] = [];

  let shouldReloadAlbum: boolean = false;

  results.forEach((result, index) => {
    if (result.status === "fulfilled") {
      result.value.value.forEach((photoResult) => {
        // If the item is already in the album, treat error as a success
        if (!photoResult.error || photoResult.error.innerError?.code === "albumItemSameNameExists") {
          flattenedResult.push({
            status: "fulfilled",
            value: undefined
          });
          modifiedPhotoIds.push(photoResult.id);

          if (photoResult.copiedItem) {
            addedPhotos.push(preparePhoto(photoResult.copiedItem));
          }
        } else if (photoResult.error.code === "copiedAlbumItemNotFound") {
          // added to album, but failed to fetch newly copied item's metadata
          shouldReloadAlbum = true;
          flattenedResult.push({
            status: "fulfilled",
            value: undefined
          });
          modifiedPhotoIds.push(photoResult.id);
        } else {
          flattenedResult.push({
            status: "rejected",
            reason: photoResult.error.code
          });
        }
      });
    } else {
      photoIds.slice(index * batchSize, (index + 1) * batchSize).forEach((photo) => {
        flattenedResult.push({
          status: "rejected",
          reason: result.reason
        });
      });
    }
  });

  return { flattenedResult, modifiedPhotoIds, addedPhotos, shouldReloadAlbum };
}
